<template>
  <el-dialog
    title="绑定主体"
    :visible.sync="dialogVisible"
    width="500px"
    :modal="false"
    @close="closeDialog">
    <el-form ref="form" :model="form" class="form" :rules="rule" :inline="true">
      <el-form-item prop="name">
        <!-- <el-input v-model="form.name" placeholder="输入机构执照全称"></el-input> -->
         <el-autocomplete
          class="inline-input"
          v-model="form.name"
          :fetch-suggestions="querySearch"
          placeholder="请输入机构执照名称"
          :trigger-on-focus="false"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item prop="code">
        <el-input v-model="form.code" placeholder="输入18位统一社会信用代码" maxlength="18"></el-input>
      </el-form-item>
    </el-form>
    <div class="bind-btn-box">
      <button class="bind-btn" @click="bindEntity">立即绑定</button>
    </div>
  </el-dialog>
</template>

<script>
import { getMemberId } from '@/assets/public/utils/token';
import { request } from '../../network';
export default {
  name: 'hasBindEntityDialog',
  data () {
    return {
      dialogVisible: this.value,
      form: {
        name: '',
        code: ''
      },
      rule: {
        name: [
          { required: true, message: '输入机构执照全称', trigger: 'blur' }
        ],
        code: [
          { required: true, validator: this.testCode, trigger: 'blur' }
        ]
      },
      restaurants: []
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeDialog () {
      this.$emit('input', this.dialogVisible);
      this.$refs.form.clearValidate();
    },
    bindEntity () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const { data: res } = await request({
            method: 'post',
            url: '/pcp/PersonalCenter/bindMember',
            data: {
              id: getMemberId(),
              name: this.form.name,
              code: this.form.code
            }
          });
          if (res.code !== 200) return this.$message.error('主体名称或信用代码不正确');
          this.$message.success('绑定成功');
          this.$store.commit('login/setEntityId', res.data.entityId);
          this.dialogVisible = false;
        }
      });
    },
    testCode (rule, value, callback) {
      if (value.length !== 18) {
        callback(new Error('输入18位统一社会信用代码'));
      } else {
        callback();
      }
    }
  },
  watch: {
    value (newVal) {
      this.dialogVisible = newVal;
    }
  }
};
</script>

<style scoped lang="less">
.bind-btn-box {
  display: flex;
  justify-content: center;
  width: 100%;
}
.bind-btn {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  border: none;
  // width: 320px;
  height: 46px;
  background: #156ED0;
  border-radius: 5px;
  cursor: pointer;
}
/deep/.el-input__inner {
  font-size: 16px;
  // width: 320px;
  height: 50px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
}
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
